/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Notification from 'react-web-notification';

type NotificationPopupProps = {
  ignore: boolean,
  title: string,
  body: string,
};

export default function NotificationPopup(props: NotificationPopupProps) {
  return (
    <div>
      <Notification
        ignore={props.ignore}
        timeout={6000}
        title={props.title}
        options={{ body: props.body }}
      />
    </div>
  );
}
