/* eslint-disable import/prefer-default-export */
/* eslint-disable object-shorthand */
import * as actionTypes from './actionTypes';

export function updateSetting(setting : ISetting) {
  return {
    type: actionTypes.UPDATE_SETTNING,
    setting: setting,
  };
}
