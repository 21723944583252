import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/configureStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import { loadState } from './utils/localStorage';
import initialStateBuilder from './redux/initialStateBuilder';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import logga from './utils/logga';

import 'ulog';

logga.debug('App loaded');

// load state from local storage
const intialState = loadState();

// config redux store with data from storage or fresh initial state
const store = configureStore(intialState ?? initialStateBuilder());  

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
