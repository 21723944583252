/* eslint-disable no-case-declarations */
import logga from '../../utils/logga';
import * as actionTypes from '../actions/actionTypes';

export default function settingsReducer(state : any = {}, action : SettingsAction) {
  logga.debug('settingsReducer: ', action.type);
  switch (action.type) {
    case actionTypes.UPDATE_SETTNING: 
      return { ...state, ...action.setting };
      break;
    default:
      logga.debug('settingsReducer: default return');
      return state;
  }
}
