/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import {
  createStore, applyMiddleware, compose, Store, 
} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import throttle from 'lodash/throttle';
import { saveState } from '../utils/localStorage';
import rootReducer from './reducers/index';
import logga from '../utils/logga';

// Be sure to ONLY add this middleware in development!
// const middleware = process.env.NODE_ENV !== 'production'
//   ? [reduxImmutableStateInvariant(), thunk]
//   : [thunk];
const middleware = process.env.NODE_ENV !== 'production'
  ? [reduxImmutableStateInvariant()]
  : [];

export default function configureStore(initialState : any) {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  logga.debug('configureStore');
  const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose; // dev tools support 
  const store : Store = createStore(
    rootReducer, 
    initialState, 
    composeEnhancers(applyMiddleware(...middleware)),
  );
  store.subscribe(throttle(() => {
    saveState(store.getState());
  }, 1000));
  
  return store;
}
