/* eslint-disable no-debugger */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { bindActionCreators } from 'redux';
import logga from '../utils/logga';
import * as taskActions from '../redux/actions/taskActions';
import './TaskDetailDialog.css';
import uniqueIDGenerator from '../utils/uniqueIDGenerator';

function TaskDetailDialog(props : any) {

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [complete, setComplete] = useState(false);
  const [priority, setPriority] = useState('N');  
  const [blocked, setBlocked] = useState(false);
  const [recurring, setRecurring] = useState(false);
  
  const getNiceDateString = (timeStamp : string, defaultString : string) : string => {
    const newTimeStampNumber = Number(timeStamp ?? 0);
    return newTimeStampNumber === 0 ? defaultString : new Date(newTimeStampNumber).toLocaleString();    
  };

  const clearState = () => {
    setTitle('');
    setDescription('');
    setComplete(false);
    setBlocked(false);
    setRecurring(false);
    setPriority('N');
  };

  const handleClose = () => {
    logga.debug('TaskDetailDialog: handleClose');
    clearState();
    // onSaveItem(null); ????
    props.handleCloseModal();
  };

  const handleDelete = () => {
    logga.debug('TaskDetailDialog: handleDelete');
    clearState();
    props.actions.deleteTask(props.task);
    props.handleCloseModal();
  };

  // useEffect hook to catch after renders and events happen
  useEffect(() => {
    if (props.task !== null) {
      logga.debug('ItemDetailModal init : itemDetail is', props.task.id);
      setTitle(props.task.title);
      setDescription(props.task.description);
      setComplete(props.task.comp);
      setPriority(props.task.priority ?? 'N');
      setBlocked(props.task.blocked ?? false);
      setRecurring(props.task.recurring ?? false);
      // debugger;
    } else {
      logga.debug('ItemDetailModal init : itemDetail is null');
      clearState();
    }
  }, [props.task]);

  const handleSave = (e : React.SyntheticEvent) => {
    logga.debug('ItemDetailModal: handleSave');
    e.preventDefault();
    let itemToSave;
    if (props.newItem) {
      logga.debug('ItemDetailModal: handleSave - new item');
      const createDate = new Date().getTime().toString();
      itemToSave = {
        id: uniqueIDGenerator(),
        title,
        description,
        comp: complete,
        blocked,
        priority,
        recurring,
        createdOn: createDate,
        updatedOn: createDate,
        completedOn: complete ? createDate : '',
      };
      props.actions.createTask(itemToSave);
    } else {
      logga.debug('ItemDetailModal: handleSave - existing item');
      let completedDateValue = props.task.completedOn ?? '';
      if (completedDateValue.length === 0 && complete) {
        // no completed date and the task is completed so must be just completed now
        completedDateValue = new Date().getTime().toString();
      }
      if (!complete) {
        // clear the completed date as now not complete
        completedDateValue = '';
      }
      itemToSave = {
        id: props.selectedTaskId,
        title,
        description,
        comp: complete,
        blocked,
        priority,
        recurring,
        createdOn: props.task.createdOn,
        updatedOn: new Date().getTime().toString(),
        completedOn: completedDateValue,
      };
      props.actions.updateTask(itemToSave);
      // debugger;
    }
    clearState();
    props.handleCloseModal();
  };

  // handle logic for mark as complete quick action button
  const handleComplete = (e : React.SyntheticEvent) => {
    logga.debug('ItemDetailModal: handleComplete');
    e.preventDefault();
    let itemToSave;
    if (!props.newItem && !props.task.comp) {
      logga.debug('ItemDetailModal: handleComplete - existing item');
      const modDate = new Date().getTime().toString();
      itemToSave = {
        id: props.selectedTaskId,
        title,
        description,
        comp: true,
        blocked,
        priority,
        recurring,
        createdOn: props.task.createdOn,
        updatedOn: modDate,
        completedOn: modDate,
      };
      props.actions.updateTask(itemToSave);
    }
    clearState();
    props.handleCloseModal();
  };

  return (
    <div className="tm-taskdetail-modal-ctr">
      <Modal
        show={props.showModal}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        dialogClassName="tm-taskdetail-modal"
        size="lg"
      >
        <Form onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>{props.newItem ? 'Create Task' : 'Edit Task'}
              <div style={{ display: 'none' }}>{props.selectedTaskId}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
         
            <Form.Group controlId="tm-taskdetail-modal-titfg">
              <Form.Label>Task</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Task" 
                defaultValue={title} 
                onKeyUp={(e : any) => setTitle(e.target.value)}
                onChange={(e : any) => setTitle(e.target.value)}
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group controlId="tm-taskdetail-modal-desfg">
              <Form.Label>Description</Form.Label>
              <Form.Control 
                as="textarea"
                placeholder="Task detail (optional)"
                defaultValue={description}
                onKeyUp={(e : any) => setDescription(e.target.value)}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Priority</Form.Label>
              <br />
              <ToggleButtonGroup
                type="radio"
                name="options"
                size="sm"
                value={priority} 
                className="tm-taskdetail-modal-pri"
                defaultValue={1}
                onChange={(value : any) => setPriority(value)}
              >
                <ToggleButton value="N">Normal</ToggleButton>
                <ToggleButton value="H">High</ToggleButton>
                <ToggleButton value="L">Low</ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>
            <Form.Group controlId="tm-taskdetail-modal-recur">
              <Form.Check 
                type="checkbox"
                label="Recurring Task"
                checked={recurring}
                onChange={(e) => setRecurring(e.target.checked)} 
              />
            </Form.Group>
            <Form.Group controlId="tm-taskdetail-modal-complete">
              <Form.Check 
                type="checkbox"
                label="Completed"
                checked={complete}
                onChange={(e) => setComplete(e.target.checked)} 
              />
            </Form.Group>
            <Form.Group controlId="tm-taskdetail-modal-blocked">
              <Form.Check 
                type="checkbox"
                label="Blocked"
                checked={blocked}
                onChange={(e) => setBlocked(e.target.checked)} 
              />
            </Form.Group>
            <div className="tm-taskdetail-metadata-block">
              <p>Created: {props.task ? getNiceDateString(props.task?.createdOn, '-') : '-'}</p>
              <p>Updated: {props.task ? getNiceDateString(props.task?.updatedOn, '-') : '-'}</p>
              <p>Completed: {props.task ? getNiceDateString(props.task?.completedOn, '-') : '-'}</p>
            </div> 
          </Modal.Body>
          <Modal.Footer>
            <DropdownButton id="tm-taskdetail-modal-dellistbtn" variant="danger" className="btn-caps" title="Delete">
              <Dropdown.Item onClick={handleDelete} className="btn-caps">Confirm Delete</Dropdown.Item>
            </DropdownButton>
            {/* <Button variant="danger" className="btn-caps" onClick={handleDelete}>Delete</Button> */}
            <Button variant="success" className="btn-caps" onClick={handleComplete} disabled={props.newItem}>Complete</Button>         
            <Button variant="primary" className="btn-caps btn-primary tm-task-detail-modal-save" type="submit">Save</Button>
            <Button variant="secondary" className="btn-caps" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </div>
  );
}

TaskDetailDialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  selectedTaskId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  task: PropTypes.object,
  handleCloseModal: PropTypes.func.isRequired,
};

function mapStateToProps(state : TasksState, ownProps : any) {
  // use prop of selectedtaskid for find the item we are displaying in state
  let selectedTask = null;
  const { selectedTaskId } = ownProps;
  logga.info(`TaskDetailDialog: Selected item - ${selectedTaskId}`);
  // Check a task id passed before query for the task, also dont query for NEW items
  if (selectedTaskId !== '' && selectedTaskId !== 'NEW') {
    selectedTask = state.tasks.filter((item) => item.id === selectedTaskId)[0];
  } 
  return {
    task: selectedTask,
    newItem: !selectedTask,
  };
}
  
function mapDispatchToProps(dispatch : any) {
  return {
    // createTask: (task : ITask) => dispatch(taskActions.createTask(task)),
    // alternative approach below, all action creators are bundled in one so new ones are auto available to the component
    actions: bindActionCreators(taskActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailDialog);
