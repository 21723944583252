/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import * as taskActions from '../redux/actions/taskActions';
import uniqueIDGenerator from '../utils/uniqueIDGenerator';
import logga from '../utils/logga';
import './TaskEntry.css';

function TaskEntry(props: any) {
  const [taskName, settaskName] = useState('');

  logga.debug('TaskEntry render');

  const handleTaskNameChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    settaskName(e.target.value);
  };

  const handleSubmit = (e : React.SyntheticEvent) => {
    e.preventDefault();
  
    logga.debug('TaskEntry: FormSubmitted');
    let newTaskName = taskName;
    let fastActionKey = '';

    // check if box is empty, if it is then open NEW item dialog
    if (taskName.trim().length === 0) {
      // request a new item dialog display 
      logga.debug('TaskEntry: FormSubmitted: request new item dialog');
      props.onRequestEditItem('NEW');
    } else {
      // fire redux action to create the item
      logga.debug('TaskEntry: FormSubmitted: Firing createTask action');
      // check for fast entry keys
      const firstChars = taskName.substring(0, 2); 
      if (firstChars === 'H ' || firstChars === 'L ' || firstChars === 'B ') {
        // ok we found a fast entry, so clean up the task name to remove it
        fastActionKey = taskName.substring(0, 1);
        newTaskName = newTaskName.substring(2);
      }
      const modDate = new Date().getTime().toString();
      props.actions.createTask({
        id: uniqueIDGenerator(), 
        title: newTaskName, 
        description: '', 
        comp: false, 
        blocked: fastActionKey === 'B', 
        priority: fastActionKey.length === 0 ? 'N' : fastActionKey,
        createdOn: modDate,
        updatedOn: modDate,
        completedOn: '',
      });
    }
    settaskName('');
  };
  return (
    <div className="tm-taskentry">
      <Form onSubmit={handleSubmit}>
        {/* <h3>Add task</h3> */}
        <input
          type="text"
          onChange={handleTaskNameChange}
          value={taskName}
          className="tm-taskentry-box"
        />
        <input type="submit" className="tm-allcaps" value="Create" />
      </Form> 
    </div>
  );  
}

TaskEntry.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state : TasksState) {
  return {
    tasks: state.tasks,
  };
}
  
function mapDispatchToProps(dispatch : any) {
  return {
    // createTask: (task : ITask) => dispatch(taskActions.createTask(task)),
    // alternative approach 
    actions: bindActionCreators(taskActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskEntry);
