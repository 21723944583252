/* eslint-disable no-case-declarations */
import logga from '../../utils/logga';
import * as actionTypes from '../actions/actionTypes';

export default function taskReducer(state : any = {}, action : TaskAction) {
  logga.debug('taskReducer: ', action.type);
  let newState = null; 
  switch (action.type) {
    case actionTypes.CREATE_TASK:
      if (action.task.priority === 'H') {
        // its a high priority so auto add to top of list - may change this later
        newState = [...state];
        newState.unshift({ ...action.task });
        return newState;
      } 
      // add to the end 
      return [...state, { ...action.task }];      
      break;

    case actionTypes.SAVE_TASKS:
      return [...action.tasks];
      break;

    case actionTypes.UPDATE_TASK:
      // return [...state, { ...action.task }];
      // eslint-disable-next-line no-debugger
      // debugger;
      // newState = [...state];
      // newState.push(Object.assign ...action.task);
      newState = state.map((item : ITask) => {
        if (item.id !== action.task.id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }    
        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.task,
        };
      });
      // eslint-disable-next-line no-debugger
      // debugger;
      return newState;
      break;
    
    case actionTypes.DELETE_TASK:
      newState = state.filter((task : ITask) => task.id !== action.task.id);
      return newState;
      break;
    
    case actionTypes.DELETE_COMPLETED_TASKS:
      newState = state.filter((task : ITask) => !task.comp);
      return newState;
      break;

    default:
      logga.debug('taskReducer: default return');
      return state;
  }
}
