/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import metadata from '../metadata.json';
import { getLogLevel, setLogLevel } from '../utils/localStorage';
import './SettingsForm.css';

export default function SettingsForm(props : any) {
  const handleClose = () => props.handleClose();

  const updateLogLevel = (level : string) => {
    setLogLevel(level);
  };

  return (
    <div>
      <Modal
        show={props.displayForm} 
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        dialogClassName="tm-settings-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter1">
            Preferences
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="tm-settings-modal-log">  
            <Form.Label>Logging</Form.Label>
            <Form.Control
              as="select"
              placeholder="None" 
              defaultValue={getLogLevel()} 
              onChange={(e : any) => updateLogLevel(e.target.value)}
            >
              <option value="1">Error</option>
              <option value="2">Warning</option>
              <option value="3">Information</option>
              <option value="4">Log</option>
              <option value="5">Debug</option>
              <option value="6">Trace</option>
              <option value="0">None</option>
              <option value="7">All</option>
            </Form.Control>
            <Form.Text className="text-muted">
              (Advanced) Only required for debug purposes.
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className="tm-settings-version">
            {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
          </div>
          <Button onClick={props.handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
