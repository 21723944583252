/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { elastic as Menu } from 'react-burger-menu';
import { MdDeleteSweep, MdSettings } from 'react-icons/md';
import { SiReadthedocs } from 'react-icons/si';
import { IoIosHourglass } from 'react-icons/io';
// import { ImSortAmountDesc } from 'react-icons/im';
// import { FaFileExport, FaFileImport } from 'react-icons/fa';
import * as taskActions from '../redux/actions/taskActions';
import logga from '../utils/logga';
import './MenuSlider.css';
import UserGuide from './UserGuide';
import SettingsForm from './SettingsForm';

function MenuSlider(props : any) {
  const [displayUserGuide, setDisplayUserGuide] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleDeleteCompTasks = (e : React.SyntheticEvent) => {
    e.preventDefault();
    logga.debug('MenuSlider: handleDeleteCompTasks');
    props.actions.deleteCompletedTasks();
    setMenuOpen(false);
  };

  // const handleAutoSortTasks = (e : React.SyntheticEvent) => {
  //   e.preventDefault();
  //   alert('Sorry this feature is not yet implemented, check back soon');
  // };

  const showUserGuide = (e : React.SyntheticEvent) => {
    e.preventDefault();
    setMenuOpen(false);
    setDisplayUserGuide(true);
  }; 

  const showSettings = (e : React.SyntheticEvent) => {
    e.preventDefault();
    setMenuOpen(false);
    setDisplaySettings(true);
  };

  const handleShowPomodoro = (e : React.SyntheticEvent) => {
    e.preventDefault();
    props.onDisplayPomodoro();
    setMenuOpen(false);
  };

  const handleCloseUserGuide = () => {
    setDisplayUserGuide(false);
  };

  const handleCloseSettings = () => {
    setDisplaySettings(false);
  };
  
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  const handleStateChange = (state : any) => {
    setMenuOpen(state.isOpen);
  };
  
  // const closeMenu = () => {
  //   setMenuOpen(false);
  // };

  // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
  return (
    <>
      <Menu 
        isOpen={menuOpen} 
        right 
        itemListElement="div" 
        width="340px" 
        pageWrapId={props.pageWrapId} 
        outerContainerId={props.outerContainerId}
        onStateChange={(state) => handleStateChange(state)}
      >
        <div>
          <a id="tm-menus-usrgde" className="tm-menu-item" href="" onClick={showUserGuide}>
            <span className="tm-menus-icon"><SiReadthedocs /></span>
            User Guide
          </a>
        </div>
        <div>
          <a id="tm-menus-pomo" className="tm-menu-item" href="" onClick={handleShowPomodoro}>
            <span className="tm-menus-icon2"><IoIosHourglass /></span>
            Display Pomodoro
          </a>
        </div>
        <div>
          <a id="tm-menus-delcomp" className="tm-menu-item" href="" onClick={handleDeleteCompTasks}>
            <span className="tm-menus-icon2"><MdDeleteSweep /></span>
            Delete Completed
          </a>
        </div>
        {/* <div>
          <a id="tm-menus-autosort" className="tm-menu-item tm-menu-disabledoption" href="" onClick={handleAutoSortTasks}>
            <span className="tm-menus-icon"><ImSortAmountDesc /></span>
            Auto Sort Tasks
          </a>
        </div>
        <div>
          <a id="tm-menus-export" className="tm-menu-item tm-menu-disabledoption" href="">
            <span className="tm-menus-icon"><FaFileExport /></span>
            Export Data
          </a>
        </div>
        <div>
          <a id="tm-menus-import" className="tm-menu-item tm-menu-disabledoption" href="">
            <span className="tm-menus-icon"><FaFileImport /></span>
            Import Data
          </a>
        </div> */}
        <div>
          <a id="tm-menus-import" className="tm-menu-item" href="" onClick={showSettings}>
            <span className="tm-menus-icon2"><MdSettings /></span>
            Preferences
          </a>
        </div>
      </Menu>
      <UserGuide displayGuide={displayUserGuide} handleClose={handleCloseUserGuide} />
      <SettingsForm displayForm={displaySettings} handleClose={handleCloseSettings} />
    </>
  );
}

function mapStateToProps(state : TasksState) {
  return {
    tasks: state.tasks,
  };
}
    
function mapDispatchToProps(dispatch : any) {
  return {
    actions: bindActionCreators(taskActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuSlider);
