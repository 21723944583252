import React, { useState } from 'react';
import './App.css';
import MenuSlider from './components/MenuSlider';
import Pomodoro from './components/Pomodoro';
import TaskDetailDialog from './components/TaskDetailDialog';
import TaskEntry from './components/TaskEntry';
import TaskList from './components/TaskList';
import FooterComponent from './layout/FooterComponent';
import Header from './layout/Header';

function App() {
  // state to drive display of pomodoro
  const [showPomodoro, setShowPomodoro] = useState(false);
  // state to drive the modal display
  const [itemBeingEdited, setItemBeingEdited] = useState(''); 
  // state to drive display options
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);

  // called from list item when user requests open the detail dialog (e.g on dbl click)
  const onRequestEditItem = (itemId : string) => {
    // set the item being edited in state
    setItemBeingEdited(itemId);
  };

  const onToggleShowCompletedTasks = () => {
    setShowCompletedTasks(!showCompletedTasks);
  };

  // called from list item when user requests to close the detail dialog 
  const handleCloseModal = () => {
    setItemBeingEdited('');
  };

  const handleClosePomodoro = () => {
    setShowPomodoro(false);
  };

  const handleOpenPomodoro = () => {
    setShowPomodoro(true);
  };

  return (
    <div className="App" id="tm-app">
      <MenuSlider pageWrapId="tm-page-wrap" outerContainerId="tm-app" onDisplayPomodoro={handleOpenPomodoro} />
      <main id="tm-page-wrap">
        <div className="tm-app-masterctnr">
          <Header />
          <div className="tm-app-container">
            <Pomodoro show={showPomodoro} onClose={handleClosePomodoro} />
            <TaskEntry onRequestEditItem={onRequestEditItem} />
            <TaskList onRequestEditItem={onRequestEditItem} showCompletedTasks={showCompletedTasks} onToggleShowCompletedTasks={onToggleShowCompletedTasks} />
            <TaskDetailDialog showModal={itemBeingEdited !== ''} selectedTaskId={itemBeingEdited} handleCloseModal={handleCloseModal} />
          </div>
          <div className="tm-app-footer">
            <FooterComponent />
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
