/* eslint-disable object-shorthand */
import * as actionTypes from './actionTypes';

export function createTask(task : ITask) {
  return {
    type: actionTypes.CREATE_TASK,
    task: task,
  };
}

export function saveTasks(tasks : ITask[]) {
  return {
    type: actionTypes.SAVE_TASKS,
    tasks: tasks,
  };
}

export function updateTask(task : ITask) {
  return {
    type: actionTypes.UPDATE_TASK,
    task: task,
  };
}

export function deleteTask(task : ITask) {
  return {
    type: actionTypes.DELETE_TASK,
    task: task,
  };
}

export function deleteCompletedTasks() {
  return {
    type: actionTypes.DELETE_COMPLETED_TASKS,
  };
}
