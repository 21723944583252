/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Timer from './Timer';
import './Pomodoro.css';
import NotificationPopup from './NotificationPopup';

type PomodoroPropType = {
  show: boolean,
  onClose: Function,
};

export default function Pomodoro(props : PomodoroPropType) {
  const [active, setActive] = useState(false);
  const [duration, setDuration] = useState(1500);
  const [pause, setPause] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  return (
    <>
      {props.show
      && (
        <div className="tm-pomo">
          <div className="tm-pomo-close" tabIndex={0} role="button" onClick={() => { props.onClose(); }} onKeyDown={() => { props.onClose(); }}>X</div>
          {/* show notification component if the showNotification state says so */}
          { showNotification && (
          <NotificationPopup
            ignore={false}
            title="Times Up!"
            body="Pomodoro Timer Expired"
          />
          )}
          <Timer 
            duration={duration} 
            paused={pause}
            active={active}
            timeCompleteAction={() => { setShowNotification(true); }}
          />
          <div className="tm-pomo-butts">
            <div>
              <Button
                variant="outline-primary"
                className="tm-pomo-btn"
                onClick={() => {
                  setShowNotification(false);
                  setDuration(1500);
                  setActive(true);
                }}
              >Pomodoro
              </Button>
              <Button
                variant="outline-primary"
                className="tm-pomo-btn"
                onClick={() => {
                  setShowNotification(false);
                  setDuration(300);
                  setActive(true);
                }}
              >Short Break
              </Button>
              <Button
                variant="outline-primary"
                className="tm-pomo-btn"
                onClick={() => {
                  setShowNotification(false);
                  setDuration(600);
                  setActive(true);
                }}
              >Long Break
              </Button>
            </div>
            <div>
              <Button variant="outline-success" className="tm-pomo-btn" onClick={() => { setShowNotification(false); setPause(false); setActive(true); }}>Start</Button>
              <Button variant="outline-warning" className="tm-pomo-btn" onClick={() => { setShowNotification(false); setPause(!pause); }}>Pause</Button>
              <Button variant="outline-danger" className="tm-pomo-btn" onClick={() => { setShowNotification(false); setActive(false); }}>Reset</Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
