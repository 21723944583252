/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import metadata from '../metadata.json';
import './UserGuide.css';

export default function UserGuide(props : any) {
  const handleClose = () => props.handleClose();

  return (
    <div>
      <Modal
        show={props.displayGuide} 
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        dialogClassName="tm-ug-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter1">
            User Guide
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>What is ZippyTodo?</h5>
          <p>
            A simple, fast, easy to use, to do list app that runs in your browser. Data is saved locally in your browser and not sent to our servers.  
          </p>
          <p>
            Quickly add new tasks then re-order them via drag and drop. Set priorities, whether the task is blocked and more!
          </p>
          <h5>How does it work?</h5>
          <p>
            Either type the task into the textbox and click the Create button. This will quickly create a new task in the list with default properties. 
          </p>
          <p>
            Alternatively just click the Create button without typing the task name and then you can access the whole set of task properties before the task is created.
          </p>
          <p>
            Access settings and more functionality via the burger menu on the right. 
          </p>
          <h5>Where's my data?</h5>
          <p>
            All data is stored in your browser and not in the cloud. You can use the Import/Export actions to backup the data and move your data to another machine if you need to.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="tm-ug-version">
            {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
          </div>
          <Button onClick={props.handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
