import React from 'react';
import './FooterComponent.css';
import metadata from '../metadata.json';

function FooterComponent() {
  const year = new Date().getUTCFullYear();
  return (
    <div className="tm-footer">
      &copy; {year} ZippyTodo.com
      <div className="tm-footer-version">
        {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
      </div>
    </div>
  );
}

export default FooterComponent;
