/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import { FcLowPriority, FcMediumPriority } from 'react-icons/fc';
import { FiArrowUpCircle, FiArrowDownCircle } from 'react-icons/fi';
import { IoRefreshCircleOutline } from 'react-icons/io5';
// import { ImBlocked } from 'react-icons/im';
import { SiAdblock } from 'react-icons/si';
import './TaskListItem.css';

export default function TaskListItem(props : any) {

  const handleClick = () => {
    props.onRequestEditItem(props.taskId);
  };

  return (
    <div 
      className="tm-tlitem"
      onClick={handleClick}
      onDoubleClick={handleClick}
    >
      <div className="tm-tlitem-row">
        <div className="tm-tlitem-col tm-tlitem-col-icons">
          <div className="tm-tlitem-iconbox">
            <span className="tm-tlitem-priority">
              {props.task.priority === 'H' ? <FiArrowUpCircle /> : ''}
              {props.task.priority === 'L' ? <FiArrowDownCircle /> : ''}
              {/* {props.task.priority === 'N' ? <FiArrowRightCircle /> : ''} */}
            </span>
            <span className="tm-tlitem-recurs">
              {props.task.recurring ? <IoRefreshCircleOutline /> : ''}
            </span>
            <span className="tm-tlitem-blkd">
              {props.task.blocked ? <SiAdblock /> : ''}
            </span>
          </div>
        </div>  
        <div className="tm-tlitem-col tm-tlitem-col-text">
          <div className={`tm-tlitem-title ${props.task.comp ? 'tm-tlitem-titlecomp' : ''}`}>
            {props.task.title}{props.taskHasDescription ? ' ...' : ''}
          </div>
        </div>
      </div>
      
    </div>
  );
}
