import logga from '../utils/logga';

// this is the intial state a clean browser will get when no local storage is present
const initialStateBuilder = () : TasksState => {
  logga.debug('initialStateBuilder called');
  const iState = {
    tasks: [{
      id: 'erbvaw776eebppi0|p4v',
      title: 'Example task to do',
      description: 'This is a sample task',
      comp: false,
      priority: 'N',
      blocked: false,
      recurring: false,
      updatedOn: '333849600000',
      createdOn: '333849600000',
      completedOn: '',
    },
    ],
    settings: { debug: 'off' }, 
  };
  return iState;
};

export default initialStateBuilder;
