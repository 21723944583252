import generateUniqueId from 'generate-unique-id';

const uniqueIDGenerator = () : string => {
  const id = generateUniqueId({
    length: 20,
    includeSymbols: ['@', '#', '|'],
    excludeSymbols: [''],
  });
  return id;
};

export default uniqueIDGenerator;
