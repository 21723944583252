import React from 'react';
import './Header.css';

export default function Header() {
  return (
    <div className="tm-header">
      <h1 className="tm-header-name">ZippyToDo<span className="tm-header-nametag">.com</span></h1>
    </div>
  );
}
