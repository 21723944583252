/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import Form from 'react-bootstrap/Form';
import * as taskActions from '../redux/actions/taskActions';
import * as settingsActions from '../redux/actions/settingsActions';
import TaskListItem from './TaskListItem';
import logga from '../utils/logga';
import './TaskList.css';

// adjust this to change proportions of list drag n drop control
const grid = 3;

// function to help us with reordering the tasks due to a shuffle
const reorder = (list : any, startIndex : any, endIndex : any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// get styling for list based on drag in progress etc
const getListStyle = (isDraggingOver : any) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  // width: 250,
});

// get styling for list item based on drag in progress etc
const getItemStyle = (isDragging : any, draggableStyle : any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  fontSize: '0.8em',
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? 'black' : '#474c56',
  // styles we need to apply on draggables
  ...draggableStyle,
});
function TaskList(props: any) {

  // we've completed a drag/drop
  function onDragEnd(result : any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // re-order the items now
    logga.debug(`TaskList:OnDragEnd:SourceIndex: ${result.source.index} ,DestIndex: ${result.destination.index}`);
    const items = reorder(
      props.tasksAll,
      result.source.index,
      result.destination.index,
    );
    // persist tasks in new order 
    logga.debug('TaskList: onDragEnd: Fire SaveTasks action');
    props.actions.saveTasks(items);
  }
  
  return (  
    <div className="tm-tasklist">
      <div className="tm-tasklist-options">
        <Form inline>
          <Form.Check
            id="tm-taskentry-chk-hideall"
            className="tm-taskentry-chk"
            type="checkbox"
            label="Hide tasks"
            checked={props.settings.hideAll}
            onChange={() => {
              // setHideAll(!hideAll);
              props.actions.updateSetting({ hideAll: !props.settings.hideAll });
            }}
          />
          <Form.Check
            id="tm-taskentry-chk-showcomp"
            className="tm-taskentry-chk"
            type="checkbox"
            label="Show completed tasks"
            checked={props.settings.showComps}
            onClick={() => {
              props.actions.updateSetting({ showComps: !props.settings.showComps });
              props.onToggleShowCompletedTasks();
            }}
            onChange={() => {
              props.actions.updateSetting({ showComps: !props.settings.showComps });
              props.onToggleShowCompletedTasks();
            }}
          />
        </Form>
      </div>
      <div className={props.settings.hideAll ? 'tm-tasklist-dndc-hidden' : 'tm-tasklist-dndc'}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {props.tasksAll.map((item : any, index : any) => (
                  <div className={!props.settings.showComps && item.comp ? 'tm-tasklist-dgable-hidden' : ''}>
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <TaskListItem taskId={item.id} task={item} taskHasDescription={item.description && item.description.length > 0} onRequestEditItem={props.onRequestEditItem} />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );  
}

TaskList.propTypes = {
  // eslint-disable-next-line react/require-default-props, react/forbid-prop-types
  tasks: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  tasksAll: PropTypes.array.isRequired,
  onRequestEditItem: PropTypes.func.isRequired,
  showCompletedTasks: PropTypes.bool.isRequired,
  onToggleShowCompletedTasks: PropTypes.func.isRequired,
};

function mapStateToProps(state : TasksState) {
  let taskList : ITask[] = state.tasks;
  if (!state.settings.showComps) {
    taskList = state.tasks.filter((task) => !task.comp);
  }
  return {
    tasks: taskList, // the list of tasks filtered to remove hidden (e.g. completed)
    tasksAll: state.tasks, // full list of tasks from state unfiltered 
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch : any) {
  return {
    // createTask: (task : ITask) => dispatch(taskActions.createTask(task)),
    // alternative approach below, all action creators are bundled in one so new ones are auto available to the component
    actions: bindActionCreators({ ...taskActions, ...settingsActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
