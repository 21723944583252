const LOCAL_STORAGE_KEY = 'TM-STATE';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
  
export const saveState = (state: TasksState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

// specific fucntions for log level which is handled outside of redux 
export const getLogLevel = () => {
  try {
    const logLevel = localStorage.getItem('log');
    return logLevel ?? '0';
  } catch (err) {
    return '0';
  }
};

// specific fucntions for log level which is handled outside of redux 
export const setLogLevel = (level: string) => {
  try {
    localStorage.setItem('log', level);
  } catch (err) {
    // Ignore write errors.
  }
};
