import { combineReducers } from 'redux';
import tasksReducer from './taskReducer'; // this is actually tasksReducer but as its default export we can name it 
import settingsReducer from './settingsReducer';

const rootReducer = combineReducers({
  tasks: tasksReducer,
  settings: settingsReducer,
});

export default rootReducer;
